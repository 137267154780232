import React, { useEffect, useMemo, useState } from "react";
import { Navigation, Pagination, FreeMode, Thumbs, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  appendImage,
  createImage,
  createMarkup,
  formatDate,
  formatDateOnly,
} from "../../utils";
import axios from "axios";
import Layout from "../../components/Layout";
import FilmFestivalHeader from "../../components/FilmFestival/Header";
import { apiRoutes } from "../../constants/apiRoutes";
import { Link } from "gatsby";
import HeroSection from "../../components/FilmFestival/HeroSection";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";
import routes from "../../components/constants/routes";
import { ImageConstants } from "../../components/constants/Imageconstants";

const CurrentSeason = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const [currentSeason, setCurrentSeason] = useState({});

  const PastSeasons =
    currentSeason?.attributes?.past_season_ff?.data?.attributes;

  const fetchCurrentSeason = async () => {
    try {
      const { data } = await axios.get(apiRoutes.filmFestivalCurrentSeason);
      if (data) {
        setCurrentSeason(data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCurrentSeason();
  }, []);

  // useMemo(() => {
  //   if (PastSeasons?.length) {
  //     const activeEvent = PastSeasons?.find((el) => el.id == currentSeason.id);
  //     if (activeEvent) {
  //       setCurrentSeason(activeEvent);
  //     } else {
  //       setCurrentSeason(PastSeasons[0]);
  //     }
  //   }
  // }, [PastSeasons, currentSeason]);

  useEffect(() => {
    appendImage("rich-editor");
  }, [currentSeason]);

  const seoData = {
    title: "Current Screenings Films | Film Festivals Luxembourg",
    description:
      "Discover the Current Screenings films at Film Festivals Luxembourg. Explore the latest cinematic creations and be part of the cultural excitement. Book your tickets now.",
    url: `${process.env.GATSBY_SITE_URL}${routes.FFCurrentSeason}/`,
  };

  return (
    <Layout>
      <>
        <Helmet
          title={seoData.title}
          description={seoData.description}
          url={seoData?.url}
        />

        <div className="film-festival">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 pr-mob-0">
                <HeroSection />
                <div className="head-tab mt-40">
                  <FilmFestivalHeader />

                  {Object.values(currentSeason).length ? (
                    <div className="tab-content" id="nav-tabContent">
                      <div className="film-current-season">
                        <div className="current-season">
                          {PastSeasons ? (
                            <div className="tickets-available">
                              <img
                                src={ImageConstants.ticketAvalable}
                                className="mb-15"
                                alt="Ticket-availables"
                                width={"auto"}
                                height={"auto"}
                              />
                              <h3>All tickets available from</h3>
                              <h2 className="">
                                {PastSeasons?.ticket_date
                                  ? formatDateOnly(PastSeasons?.ticket_date)
                                  : ""}
                                <span className="">
                                  {" "}
                                  from{" "}
                                  {
                                    <Link href={PastSeasons?.ticket_link}>
                                      {PastSeasons?.ticket_link}
                                    </Link>
                                  }
                                </span>
                              </h2>
                              <div
                                className="rich-editor spring-festival"
                                dangerouslySetInnerHTML={createMarkup(
                                  PastSeasons?.sub_title
                                )}
                              ></div>
                            </div>
                          ) : null}

                          {PastSeasons?.youtube_url ? (
                            <div className="current-season-yt mt-40">
                              <iframe
                                width="826"
                                height="468"
                                src={PastSeasons?.youtube_url}
                              ></iframe>
                            </div>
                          ) : null}
                          <div className="ff-presents">
                            <h2 className="ff-presents-title mt-80 mb-5">
                              {PastSeasons
                                ? PastSeasons?.flim_festival_title
                                : currentSeason?.attributes?.title}
                            </h2>

                            {!PastSeasons ? (
                              <div className="container">
                                <div
                                  className="rich-editor"
                                  dangerouslySetInnerHTML={createMarkup(
                                    currentSeason?.attributes
                                      ?.flim_festival_description
                                  )}
                                ></div>
                              </div>
                            ) : null}

                            {PastSeasons ? (
                              <>
                                <Swiper
                                  onSwiper={setThumbsSwiper}
                                  freeMode={true}
                                  watchSlidesProgress={true}
                                  // centeredSlidesBounds={false}
                                  // loop = {true}
                                  // centeredSlides = {true}
                                  spaceBetween={20}
                                  modules={[
                                    FreeMode,
                                    Navigation,
                                    Pagination,
                                    Thumbs,
                                    Autoplay,
                                  ]}
                                  className="mySwiper"
                                  navigation
                                  pagination={{ clickable: true }}
                                  // autoplay={{
                                  //   delay: 10000,
                                  //   disableOnInteraction: false,
                                  // }}
                                  breakpoints={{
                                    320: {
                                      slidesPerView: 3,
                                      spaceBetween: 20,
                                    },
                                    768: {
                                      slidesPerView: 7,
                                      spaceBetween: 10,
                                    },
                                    1024: {
                                      slidesPerView: 12,
                                      spaceBetween: 10,
                                    },
                                    1366: {
                                      slidesPerView: 12,
                                    },
                                    1920: {
                                      slidesPerView: 15,
                                      spaceBetween: 0,
                                    },
                                  }}
                                >
                                  {PastSeasons?.flims?.data?.map((film) => (
                                    <SwiperSlide>
                                      <img
                                        src={createImage(
                                          film.attributes.image.data.attributes
                                            .url
                                        )}
                                        alt={film.attributes.image_alt_text}
                                        width={"auto"}
                                        height={"auto"}
                                      />
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                                <Swiper
                                  spaceBetween={20}
                                  navigation={true}
                                  autoHeight={true}
                                  // centeredSlides={true}
                                  // loop={true}
                                  // autoplay={{
                                  //   delay: 10000,
                                  //   disableOnInteraction: false,
                                  // }}
                                  thumbs={{ swiper: thumbsSwiper }}
                                  modules={[
                                    FreeMode,
                                    Navigation,
                                    Thumbs,
                                    // Autoplay,
                                  ]}
                                  className="mySwiper2"
                                >
                                  {PastSeasons?.flims?.data?.map((film) => {
                                    return (
                                      <SwiperSlide>
                                        <div className="slider-display">
                                          <div className="row">
                                            <div className="col-md-4">
                                              <div className="slider-display-col">
                                                <img
                                                  src={createImage(
                                                    film.attributes.image.data
                                                      .attributes.url
                                                  )}
                                                  alt={
                                                    film.attributes
                                                      .image_alt_text
                                                  }
                                                  width={"auto"}
                                                  height={"auto"}
                                                />
                                              </div>
                                              {film?.attributes?.trailer_url ? (
                                                <div className="view-trailor">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="30"
                                                    viewBox="0 -960 960 960"
                                                    width="30"
                                                  >
                                                    <path d="m383-310 267-170-267-170v340Zm97 230q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
                                                  </svg>
                                                  <Link
                                                    to={
                                                      film?.attributes
                                                        ?.trailer_url ?? "#"
                                                    }
                                                    target="_blank"
                                                    className="view-trailor"
                                                  >
                                                    Click here to view the
                                                    Trailer
                                                  </Link>
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="col-md-8">
                                              {!film?.attributes
                                                ?.use_ckeditor ? (
                                                <div className="ff-prents-movies">
                                                  <h2>
                                                    {film?.attributes.title}
                                                  </h2>

                                                  {film?.attributes.date ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Date:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {film?.attributes.date}
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.time ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Time:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {film?.attributes.time}
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.venue ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Venue:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {film?.attributes.venue}
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.country ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Country:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {
                                                          film?.attributes
                                                            .country
                                                        }
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.genre ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Genre:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {film?.attributes.genre}
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.duration ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Duration:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {
                                                          film?.attributes
                                                            .duration
                                                        }
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes
                                                    .directed_by ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Directed by:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {
                                                          film?.attributes
                                                            .directed_by
                                                        }
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.starring ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Starring:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {
                                                          film?.attributes
                                                            .starring
                                                        }
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.synopsis ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Synopsis:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {
                                                          film?.attributes
                                                            .synopsis
                                                        }
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.about ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        About:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {film?.attributes.about}
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.price ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Price:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        {film?.attributes.price}
                                                      </span>
                                                    </div>
                                                  ) : null}

                                                  {film?.attributes.tickets ? (
                                                    <div className="row mb-2">
                                                      <span className="col-md-2 w-30 font-weight-800">
                                                        Tickets:
                                                      </span>
                                                      <span className="col-md-10 w-70">
                                                        <Link
                                                          to={
                                                            film?.attributes
                                                              .tickets
                                                          }
                                                          target="_blank"
                                                        >
                                                          {" "}
                                                          {
                                                            film?.attributes
                                                              .tickets
                                                          }
                                                        </Link>
                                                      </span>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : (
                                                <div className="ff-prents-movies">
                                                  <h2>
                                                    {film?.attributes.title}
                                                  </h2>
                                                  <div
                                                    className="rich-editor ml-14"
                                                    dangerouslySetInnerHTML={createMarkup(
                                                      film.attributes
                                                        .description
                                                    )}
                                                  ></div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    );
                                  })}
                                </Swiper>
                              </>
                            ) : null}
                          </div>

                          {PastSeasons?.sponsor_image?.data?.attributes?.url ? (
                            <div className="sponsors mt-50">
                              <h1 className="title text-center">Sponsors</h1>
                              <div className="container-fluid mt-30">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="sponsors-container">
                                      <img
                                        src={createImage(
                                          PastSeasons?.sponsor_image?.data
                                            ?.attributes?.url
                                        )}
                                        alt={
                                          PastSeasons?.sponsor_image_alt_text
                                        }
                                        width={"auto"}
                                        height={"auto"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {PastSeasons?.winnerBox?.length ? (
                            <div className="winner-biffl">
                              <h1 className="title text-center pt-30">
                                {PastSeasons?.winnerBox[0]?.title}
                              </h1>
                              <div className="container mt-30">
                                <div className="row">
                                  {PastSeasons?.winnerBox?.map((winner) => (
                                    <div className="col-md-6">
                                      <img
                                        src={createImage(
                                          winner?.wining_type?.data?.attributes
                                            ?.url
                                        )}
                                        alt={winner?.wining_type_alt_text}
                                        width={"auto"}
                                        height={"auto"}
                                        className="biffl-award"
                                      />
                                      <div className="winner-biffl-container">
                                        <div className="position-relative">
                                          <img
                                            src={createImage(
                                              winner?.flim_image?.data
                                                ?.attributes?.url
                                            )}
                                            alt={winner?.flim_image_alt_text}
                                            width={"auto"}
                                            height={"auto"}
                                          />
                                          {/* <div className="film-details">
                                        <p>
                                          <strong>Directed by :</strong> Colm
                                          Bairéad
                                        </p>
                                        <p>
                                          <strong>Starring :</strong>r Catherine
                                          Clinch, Carrie Crowley, Andrew Bennett
                                        </p>
                                        <p>
                                          <strong>Genre :</strong>{" "}
                                          Irish-language drama
                                        </p>
                                        <p>
                                          <strong>Duration :</strong> 94 mins
                                        </p>
                                      </div> */}
                                        </div>
                                        <h4>{winner?.title}</h4>
                                        {winner?.youtube_url ? (
                                          <div className="view-trailor">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="30"
                                              viewBox="0 -960 960 960"
                                              width="30"
                                            >
                                              <path d="m383-310 267-170-267-170v340Zm97 230q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
                                            </svg>
                                            <Link
                                              to={winner?.youtube_url ?? "#"}
                                              className="view-trailor"
                                              target="_blank"
                                            >
                                              Click here to view the Trailer
                                            </Link>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <p className="text-center" style={{ margin: "5em 0" }}>
                        <div className="screenLoader">
                          <img src={ImageConstants.screenloader} alt="h" />
                        </div>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default CurrentSeason;
